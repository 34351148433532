import React from 'react'
import Navbar from '../Components/Navbar'

const ContactUS = () => {
    return (
        <div>
            {/* <div className='container-fluid '>
                <div className='row'><Navbar /></div>

                <div className='row main-cont'>
                    <div className='col-md-6'>
                        <h1>Hire Me</h1>
                        <p>I am available for freelance work. Connect with me via phone: 01923 088574 or email: admin@example.com</p>

                        <input type='text' className='form-control mb-3' placeholder='Your Name' />
                        <input type='text' className='form-control mb-3' placeholder='Your Email' />
                        <input type='text' className='form-control mb-3' placeholder='Phone Number' />
                        <input type='text' className='form-control mb-3' placeholder='Subject' />
                        <textarea type='textarea' className='form-control mb-3' placeholder='Your Name' />

                    </div>
                    <div className='col-md-6'>
                        <img class="w-100 imag-size" src="./images/about3.jpg" alt="About Images" />



                    </div>

                </div>

            </div> */}

        </div>
    )
}

export default ContactUS
